import { useNavigate } from "react-router-dom";
import { AccountName, AppRoute, AuthStatus } from "../../../const";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setAuthModalOpened } from "../../../store/actions";
import Button from "../../button/button";

function BinanceButtonGroup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authStatus, isBinanceSpotConnected, isBinanceFuturesConnected } =
    useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
      return;
    }
    navigate(`${AppRoute.Accounts}/${AccountName.BinanceSpot}/`);
  };

  const handleViewClick = () => {
    if (isBinanceFuturesConnected) {
      navigate(`${AppRoute.Accounts}/${AccountName.BinanceFutures}/`);
      return;
    }
    if (isBinanceSpotConnected) {
      navigate(`${AppRoute.Accounts}/${AccountName.BinanceSpot}/`);
      return;
    }
    navigate(`${AppRoute.Accounts}/${AccountName.BinanceSpot}/`);
    return;
  };

  return (
    <div className="exchange-promo__btns">
      {isBinanceSpotConnected === null && isBinanceFuturesConnected === null ? (
        <div className="landing__btn-gap"></div>
      ) : isBinanceFuturesConnected || isBinanceSpotConnected ? (
        <Button
          pattern="white-blue"
          text="View trading results"
          type="button"
          icon="eye"
          size="super"
          handleClick={handleViewClick}
        />
      ) : (
        <>
          <Button
            pattern="white-blue"
            text="Connect an existing account"
            icon="check"
            type="button"
            size="huge"
            handleClick={handleConnectClick}
          />
        </>
      )}
    </div>
  );
}

export default BinanceButtonGroup;
