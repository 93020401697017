import { createAsyncThunk } from "@reduxjs/toolkit";

import { ThunkApiConfigType } from "../../types/api";
import { StateAction } from "../action-types";
import { checkAuthAndRegistration } from "../../utils/params";
import { getRedirectAccount } from "../../services/redirect-account";
// import {
// fetchOverviewSummary,
//   fetchStrategies,
// } from "./overview";
import { checkMessagesAction, fetchNavAccounts } from "../api-actions";
import {
  AccountActionPath,
  AccountName,
  ApiRoute,
  AppRoute,
  AuthStatus,
} from "../../const";
import {
  setAuthModalOpened,
  setAuthModalOpenedFromExchanges,
  setOkxModalOpened,
  setPersonalOpened,
} from "../actions";
import {
  loadUserAuthAccountsStatus,
  loadUserProfile,
  redirectToRoute,
  requireAuthorization,
  setAccountStatuses,
  setBitgetConnected,
  setBitgetRedirectFromApple,
  setOkxConnected,
  setBybitConnected,
  setOkxRedirectFromApple,
  setUserProfileLoading,
  setIsAccountStatusesLoading,
  setBinanceSpotConnected,
  setBinanceFuturesConnected,
} from "../actions/user";
import { Axios, AxiosError } from "axios";

export const checkAuthedAccounts = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(StateAction.User.RequireAuth, async (_arg, { dispatch, extra: api }) => {
  const { data } = await api.get(ApiRoute.Login);

  dispatch(loadUserAuthAccountsStatus(data));

  if (Object.values(data).some((key) => key === true)) {
    dispatch(requireAuthorization(AuthStatus.Auth));
    return;
  }
  dispatch(requireAuthorization(AuthStatus.NoAuth));
});

export const fetchUserProfile = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(StateAction.User.LoadUserProfile, async (_arg, { dispatch, extra: api }) => {
  dispatch(setUserProfileLoading(true));
  try {
    const { data } = await api.get(ApiRoute.User);

    const name = data.client_name;
    const emails = {
      apple: data.emails.apple,
      google: data.emails.google,
    };
    const isAffiliate = data.is_affiliate;
    const clientId = data.client_id;
    const isShowSummary = data.show_summary;

    dispatch(
      loadUserProfile({ name, emails, isAffiliate, clientId, isShowSummary })
    );
  } catch {}

  dispatch(setUserProfileLoading(false));
});

export const checkAccountStatuses = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.User.SetAccountStatuses,
  async (_arg, { dispatch, extra: api }) => {
    try {
      dispatch(setIsAccountStatusesLoading(true));
      const { data } = await api.get(ApiRoute.AccountStatuses);
      dispatch(
        setAccountStatuses({
          active: data.active,
          already_launched: data.already_launched,
          okx_trading_started: data.okx_trading_started,
          bitget_futures_trading_started: data.bitget_futures_trading_started,
          bybit_trading_started: data.bybit_trading_started,
        })
      );
    } catch {
    } finally {
      dispatch(setIsAccountStatusesLoading(false));
    }
  }
);

export const sendUserAppleInfo = createAsyncThunk<
  void,
  any,
  ThunkApiConfigType
>(
  StateAction.User.SendUserAppleInfo,
  async (
    { info, isOverview, isRedirectToOkx, isRedirectToBitget },
    { dispatch, extra: api }
  ) => {
    try {
      const { data } = await api.post(ApiRoute.AppleLogin, info);
      checkAuthAndRegistration(data?.success_auth, data?.success_registration);

      // Закрыть модальные окна
      dispatch(setOkxModalOpened(false));
      dispatch(setAuthModalOpened(false));
      dispatch(setPersonalOpened(false));

      // Запросы как при загрузке приложения
      dispatch(checkOkxConnected());
      dispatch(checkBitgetConnected());
      dispatch(checkBybitConnected());
      dispatch(checkBinanceFuturesConnected());
      dispatch(checkBinanceSpotConnected());
      dispatch(checkAuthedAccounts());
      dispatch(fetchUserProfile());
      dispatch(checkAccountStatuses());
      dispatch(fetchNavAccounts());

      if (isRedirectToOkx) {
        dispatch(setOkxRedirectFromApple(true));
        dispatch(redirectToRoute(`${AppRoute.Accounts}/${AccountName.Okx}/`));
        return;
      }

      if (isRedirectToBitget) {
        dispatch(setBitgetRedirectFromApple(true));
        dispatch(
          redirectToRoute(`${AppRoute.Accounts}/${AccountName.BitgetFutures}/`)
        );
        return;
      }

      const account = getRedirectAccount();
      if (account) {
        dispatch(redirectToRoute(`${AppRoute.Accounts}/${account}/`));
        dispatch(setAuthModalOpenedFromExchanges(false));
        return;
      }

      if (!isOverview) {
        dispatch(redirectToRoute(AppRoute.Overview));
        return;
      }

      // Overview
      // fetchStrategies и fetchOverviewSummary не вызываю, тк на странице overview есть зависимости в useEffect, при изменении статуса авторизации пойдут запросы
      // dispatch(fetchStrategies());
      // dispatch(fetchOverviewSummary());
      dispatch(checkMessagesAction());
    } catch {}
  }
);

export const checkOkxConnected = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(StateAction.User.SetOkxConnected, async (_arg, { dispatch, extra: api }) => {
  try {
    const { data } = await api.get(
      `${ApiRoute.AccountDetails}${AccountName.Okx}${AccountActionPath.ACCOUNT_KEYS}`
    );
    dispatch(setOkxConnected(data?.active));
  } catch {
    dispatch(setOkxConnected(false));
  }
});

export const checkBybitConnected = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.User.SetBybitConnected,
  async (_arg, { dispatch, extra: api }) => {
    try {
      const { data } = await api.get(
        `${ApiRoute.AccountDetails}${AccountName.Bybit}${AccountActionPath.ACCOUNT_KEYS}`
      );
      dispatch(setBybitConnected(data?.active));
    } catch {
      dispatch(setBybitConnected(false));
    }
  }
);
export const checkBinanceSpotConnected = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.User.SetBinanceSpotConnected,
  async (_arg, { dispatch, extra: api }) => {
    try {
      const { data } = await api.get(
        `${ApiRoute.AccountDetails}${AccountName.BinanceSpot}${AccountActionPath.ACCOUNT_KEYS}`
      );
      dispatch(setBinanceSpotConnected(data?.active));
    } catch (err) {
      dispatch(setBinanceSpotConnected(false));
    }
  }
);

export const checkBinanceFuturesConnected = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.User.SetBinanceFuturesConnected,
  async (_arg, { dispatch, extra: api }) => {
    try {
      const { data } = await api.get(
        `${ApiRoute.AccountDetails}${AccountName.BinanceFutures}${AccountActionPath.ACCOUNT_KEYS}`
      );
      dispatch(setBinanceFuturesConnected(data?.active));
    } catch (err) {
      dispatch(setBinanceFuturesConnected(false));
    }
  }
);

export const checkBitgetConnected = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.User.SetBitgetConnected,
  async (_arg, { dispatch, extra: api }) => {
    try {
      const { data } = await api.get(
        `${ApiRoute.AccountDetails}${AccountName.BitgetFutures}${AccountActionPath.ACCOUNT_KEYS}`
      );
      dispatch(setBitgetConnected(data?.active));
    } catch {
      dispatch(setBitgetConnected(false));
    }
  }
);
