import { useEffect, useState } from "react";

import BinancePromo from "../../components/promos/binance-promo";
import Bots from "../../components/bots/bots";
import BinancePluses from "../../components/pluses/binance-pluses";
import BinanceFaq from "../../components/faq/binance-faq";
import BinanceBriefly from "../../components/brieflies/binance-briefly";
import BinanceSteps from "../../components/steps/binance-steps";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch } from "../../hooks";
import { trackEvent } from "../../utils/tracking";
import { deleteSeoData } from "../../utils/general";
import { BinanceLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import {
  fetchBinanceFuturesDemo,
  fetchBinanceSpotDemo,
  fetchDiversifiedDemo,
  fetchOkxDemo,
  fetchBybitDemo,
} from "../../store/api-actions/demo";
import { checkIfMobile } from "../../utils/breakpoint";
import { RERENDER_DELAY } from "../../const";
import { debounce } from "../../utils/chart/chart";

export default function BinanceScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const [isMobile, setIsMobile] = useState<boolean>(checkIfMobile());

  // any page
  useEffect(() => {
    trackEvent("BinanceLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  useEffect(() => {
    dispatch(fetchOkxDemo());
    dispatch(fetchBinanceSpotDemo());
    dispatch(fetchBinanceFuturesDemo());
    dispatch(fetchBybitDemo());
    dispatch(fetchDiversifiedDemo());
  }, [dispatch]);

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsMobile(checkIfMobile());
    }, RERENDER_DELAY)
  );

  return (
    <main className="main main--landing landing">
      <BinanceLandingSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        <BinancePromo isMobile={isMobile} />
        <BinancePluses />
        <BinanceSteps />
        <BinanceBriefly />
        <Bots />
        <BinanceFaq />
      </div>
    </main>
  );
}
