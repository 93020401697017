import { useEffect, useState } from "react";

import BitgetPromo from "../../components/promos/bitget-promo";
import Bots from "../../components/bots/bots";
import BitgetSteps from "../../components/steps/bitget-steps";
import BitgetFaq from "../../components/faq/bitget-faq";
import BitgetBriefly from "../../components/brieflies/bitget-briefly";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch } from "../../hooks";
import { deleteSeoData } from "../../utils/general";
import { BitgetLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import { trackEvent } from "../../utils/tracking";
import { fetchBitgetFuturesDemo } from "../../store/api-actions/demo";
import { debounce } from "../../utils/chart/chart";
import { checkIfMobile } from "../../utils/breakpoint";
import { RERENDER_DELAY } from "../../const";
import GeneralExchangePluses from "../../components/pluses/general-exchange-pluses";

export default function BitgetScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const [isMobile, setIsMobile] = useState<boolean>(checkIfMobile());

  // any page
  useEffect(() => {
    trackEvent("BitgetLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  useEffect(() => {
    dispatch(fetchBitgetFuturesDemo());
  }, [dispatch]);

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsMobile(checkIfMobile());
    }, RERENDER_DELAY)
  );

  return (
    <main className="main main--landing landing">
      <BitgetLandingSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        <BitgetPromo isMobile={isMobile} />
        <GeneralExchangePluses />
        <BitgetSteps />
        <BitgetBriefly />
        <Bots />
        <BitgetFaq />
      </div>
    </main>
  );
}
