import { useNavigate } from "react-router-dom";
import {
  AccountName,
  AppRoute,
  AuthStatus,
  BITGET_REGISTER_LINK,
} from "../../../const";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setAuthModalOpened } from "../../../store/actions";
import { trackEvent } from "../../../utils/tracking";
import Button from "../../button/button";
import { createBitgetConnectLink } from "../../../utils/id";

function BitgetButtonGroup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authStatus, isBitgetConnected } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const bitgetConnectLink = createBitgetConnectLink();

  const handleRegisterClick = () => {
    trackEvent("BitgetLandingRegisterAccount", "Clicked");
  };

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("BitgetLandingFastConnect", "Clicked");
  };

  const handleViewClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(`${AppRoute.Accounts}/${AccountName.BitgetFutures}/`);
    } else {
      dispatch(setAuthModalOpened(true));
    }
  };

  return (
    <div className="exchange-promo__btns">
      {isBitgetConnected === null ? (
        <div className="landing__btn-gap"></div>
      ) : isBitgetConnected ? (
        <Button
          pattern="white-blue"
          text="View trading results"
          type="button"
          icon="eye"
          size="super"
          handleClick={handleViewClick}
        />
      ) : (
        <>
          <Button
            pattern="white-blue"
            text="Register Bitget account"
            icon="key-in-circle"
            type="link"
            size="super-big"
            linkAddress={BITGET_REGISTER_LINK}
            handleClick={handleRegisterClick}
          />
          <Button
            pattern="blue-white"
            text="Connect an existing account"
            icon="check"
            type={isNoAuth ? "button" : "link"}
            size="huge"
            linkAddress={bitgetConnectLink}
            isSelfLink
            handleClick={handleConnectClick}
          />
        </>
      )}
    </div>
  );
}

export default BitgetButtonGroup;
