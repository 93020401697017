import { useNavigate } from "react-router-dom";
import {
  AccountName,
  AppRoute,
  AuthStatus,
  OKX_REGISTER_LINK,
} from "../../../const";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setAuthModalOpened } from "../../../store/actions";
import { createOkxConnectLink } from "../../../utils/id";
import { trackEvent } from "../../../utils/tracking";
import Button from "../../button/button";

function OkxPromoButtonGroup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authStatus, isOkxConnected } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const okxConnectLink = createOkxConnectLink();

  const handleRegisterClick = () => {
    trackEvent("OKXLandingRegisterAccount", "Clicked");
  };

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("OKXLandingFastConnect", "Clicked");
  };

  const handleViewClick = () => {
    navigate(`${AppRoute.Accounts}/${AccountName.Okx}/`);
  };

  return (
    <div className="exchange-promo__btns">
      {isOkxConnected === null ? (
        <div className="landing__btn-gap"></div>
      ) : isOkxConnected ? (
        <Button
          pattern="white-blue"
          text="View trading results"
          type="button"
          icon="eye"
          size="super"
          handleClick={handleViewClick}
        />
      ) : (
        <>
          <Button
            pattern="blue-white"
            text="Register an OKX account"
            icon="key-in-circle"
            type="link"
            size="super-big"
            linkAddress={OKX_REGISTER_LINK}
            handleClick={handleRegisterClick}
          />
          <Button
            pattern="white-blue"
            text="Connect an existing account"
            icon="check"
            type={isNoAuth ? "button" : "link"}
            size="huge"
            linkAddress={okxConnectLink}
            isSelfLink
            handleClick={handleConnectClick}
          />
        </>
      )}
    </div>
  );
}

export default OkxPromoButtonGroup;
