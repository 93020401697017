import { createReducer } from "@reduxjs/toolkit";

import { AuthStatus } from "../const";
import { ReferralsType } from "../types/referrals";
import { CalcChartsType } from "../types/chart";
import { OverviewSummaryType, StrategyType } from "../types/strategy";
import { CoinType, TradableCoinType } from "../types/coins";
import { CoinTogglesType } from "../types/interface";
import { setCoinToggles, setNavOpened } from "./actions/interface";
import {
  loadPaymentsInfo,
  setLoadPaymentsInfoLoading,
} from "./actions/payments";
import {
  ResponseStatusType,
  QuotesItemsType,
  StrategyDetailsType,
  AmountToStartType,
} from "../types/details";
import {
  setAddKeysLoading,
  setAddKeysStatus,
  setSendRenewKeysToCheckLoading,
  setSendRenewKeysToCheckStatus,
  setSendRenewKeysToUpdateLoading,
  setSendRenewKeysToUpdateStatus,
} from "./actions/keys";
import {
  loadBinanceFuturesDemo,
  loadBinanceSpotDemo,
  loadBitgetFuturesDemo,
  loadBybitDemo,
  loadDiversifiedDemo,
  loadOkxDemo,
  setBinanceFuturesDemoLoading,
  setBinanceSpotDemoLoading,
  setBitgetFuturesDemoLoading,
  setBybitDemoLoading,
  setOkxDemoLoading,
} from "./actions/demo";
import {
  AddedCardStatusType,
  CryptoWalletType,
  FailedTransactionsType,
  LastPaymentType,
  PaymentsInfoType,
  PaymentMethodsType,
} from "../types/payment";
import {
  UserProfileType,
  UserAuthAccountsType,
  NavAccountType,
  MessagesType,
  NotificationType,
  AccountStatusesType,
  DemoType,
  DiversifiedDemoType,
} from "../types/general";
import {
  loadAmountToStart,
  setChangePositionModeLoading,
  setChangePositionModeStatus,
  setCheckPositionModeLoading,
  setCheckPositionModeStatus,
  setCheckAccountUnifiedStatus,
  setLoadAmountToStartLoading,
  setLoadAmountToStartStatus,
  setSendAmountToStartLoading,
  setSendAmountToStartStatus,
} from "./actions/start";
import {
  loadNavAccounts,
  requireMessages,
  loadStrategyDetails,
  loadFaqChart,
  setOpenedHint,
  addReadHintId,
  loadReferrals,
  loadClientSecret,
  loadPaymentMethods,
  addCard,
  setSendAddedCardStatus,
  loadLastPayments,
  deleteCard,
  setPaymentMethodsLoading,
  setLastPaymentsLoading,
  setAddCardLoading,
  setAccountsLoading,
  setReferralsLoading,
  setStrategyDetailsLoading,
  setRenewKeysOpened,
  setFirstScreenRendered,
  setSendMethodsOrderStatus,
  loadCryptoWallet,
  addDepositInLastPayments,
  setSendDepositInLastPaymentsStatus,
  loadFailedTransactions,
  setFailedTransactionsAgainLoading,
  setCryptoFormChosen,
  setCardFormChosen,
  setFailedTransactionsLoading,
  setNavAccountsOpened,
  loadNotifications,
  setNotificationsLoading,
  resetNotificationsInMessages,
  setFixDivergenceLoading,
  loadCalcChart,
  setErrorModalOpened,
  resetErrorInMessages,
  setStopModalOpened,
  setStopModalId,
  setDeleteCardModalOpened,
  setDeleteCardModalId,
  setAuthModalOpened,
  setPersonalOpened,
  setNotificationsOpened,
  setAuthModalOpenedFromExchanges,
  setIntegrationCalendarOpened,
  loadTradableCoins,
  setTradableCoinsLoading,
  setIntegrationOpened,
  loadCoin,
  setCoinLoading,
  setPartnershipFormFocused,
  setOkxModalOpened,
  setOkxModalRendered,
  setBitgetModalRendered,
  setBitgetModalOpened,
  setCalcChartLoading,
  setBybiytModalOpened,
  setBybitModalRendered,
} from "./actions";
import {
  addSubscribedAccount,
  loadOverviewSummary,
  loadStrategies,
  setOverviewSummaryLoading,
  setOverviewSummaryStatus,
  setStrategiesLoading,
} from "./actions/overview";
import {
  loadUserAuthAccountsStatus,
  loadUserProfile,
  requireAuthorization,
  setAccountStatuses,
  setBitgetConnected,
  setBitgetRedirectFromApple,
  setOkxConnected,
  setBybitConnected,
  setBinanceFuturesConnected,
  setBinanceSpotConnected,
  setOkxRedirectFromApple,
  setUserProfileLoading,
  setIsAccountStatusesLoading,
} from "./actions/user";

type initialStateType = {
  // User
  authStatus: string;
  userAuthAccounts: UserAuthAccountsType;
  userProfile: null | UserProfileType;
  isUserProfileLoading: boolean;
  accountStatuses: null | AccountStatusesType;
  isAccountStatusesLoading: boolean
  isOkxConnected: null | boolean;
  isBybitConnected: null | boolean;
  isBinanceSpotConnected: null | boolean;
  isBinanceFuturesConnected: null | boolean;
  isOkxRedirectFromApple: boolean;
  isBitgetConnected: null | boolean;
  isBitgetRedirectFromApple: boolean;

  // Message
  messages: MessagesType;
  notifications: null | NotificationType[];
  isNotificationsLoading: boolean;

  // Nav
  navAccounts: NavAccountType[];
  isNavAccountsLoading: boolean;

  // Overview
  overviewSummary: null | OverviewSummaryType;
  isOverviewSummaryLoading: boolean;
  overviewSummaryStatus: null | ResponseStatusType;
  strategies: StrategyType[];
  isStrategiesLoading: boolean;
  subscribedAccounts: string[];

  // Details
  strategyDetails: StrategyDetailsType | null;
  isStrategyDetailsLoading: boolean;

  // Integration
  amountToStart: null | AmountToStartType;
  isLoadAmountToStartLoading: boolean;
  loadAmountToStartStatus: null | ResponseStatusType;

  isSendAmountForStartLoading: boolean;
  sendAmountToStartStatus: null | ResponseStatusType;

  isAddKeysLoading: boolean;
  addKeysStatus: null | ResponseStatusType;

  isChangePositionModeLoading: boolean;
  changePositionModeStatus: null | ResponseStatusType;

  isCheckPositionModeLoading: boolean;
  checkPositionModeStatus: null | ResponseStatusType;
  checkAccountUnifiedStatus: null | ResponseStatusType;

  // RenewKeys
  checkRenewKeysStatus: null | ResponseStatusType;
  isCheckRenewKeysLoading: boolean;

  saveRenewKeysStatus: null | ResponseStatusType;
  isSaveRenewKeysLoading: boolean;

  isFixDivergenceLoading: boolean;

  // Referrals
  referrals: null | ReferralsType;
  isReferralsLoading: boolean;

  // Chart
  faqChart: null | QuotesItemsType;
  calcChart: null | CalcChartsType;
  isCalcChartLoading: boolean;

  // Payment
  clientSecret: null | string;
  paymentsInfo: null | PaymentsInfoType;
  isPaymentsInfoLoading: boolean;
  paymentMethods: PaymentMethodsType;
  isPaymentMethodsLoading: boolean;
  methodsOrderStatus: null | boolean;
  isAddedCardLoading: boolean;
  addedCardStatus: null | AddedCardStatusType;

  lastPayments: LastPaymentType[];
  isLastPaymentsLoading: boolean;

  cryptoWallet: null | CryptoWalletType;
  depositInLastPaymentsStatus: null | boolean;

  failedTransactions: FailedTransactionsType[];
  isFailedTransactionsLoading: boolean;
  isFailedTransactionsAgainLoading: boolean;

  isCryptoFormChosen: boolean;
  isCardFormChosen: boolean;

  // Interface
  isFirstScreenRendered: boolean;
  isPartnershipFormFocused: boolean;

  openedHint: null | string;
  readHintIds: string[];

  isNavAccountsOpened: boolean;
  isNotificationsOpened: boolean;
  isPersonalOpened: boolean;
  isNavOpened: boolean;

  isIntegrationOpened: boolean;
  isRenewKeysFormOpened: boolean;

  coinToggles: CoinTogglesType;

  // Modal
  isAuthModalOpened: boolean;
  isAuthModalOpenedFromExchanges: boolean;
  isErrorModalOpened: boolean;

  isStopModalOpened: boolean;
  stopModalId: null | string;

  isDeleteCardModalOpened: boolean;
  deleteCardModalId: null | string;
  isOkxModalOpened: boolean;
  isOkxModalRendered: boolean;
  isBitgetModalOpened: boolean;
  isBitgetModalRendered: boolean;
  isBybitModalOpened: boolean,
  isBybitModalRendered: boolean,

  isIntegrationCalendarOpened: boolean;

  // Coins
  tradableCoins: TradableCoinType[];
  isTradableCoinsLoading: boolean;
  coin: null | CoinType;
  isCoinLoading: boolean;

  // Demo
  okxDemo: null | DemoType;
  isOkxDemoLoading: boolean;

  binanceSpotDemo: null | DemoType;
  isBinanceSpotDemoLoading: boolean;

  binanceFuturesDemo: null | DemoType;
  isBinanceFuturesDemoLoading: boolean;

  bitgetFuturesDemo: null | DemoType;
  isBitgetFuturesDemoLoading: boolean;

  bybitDemo: null | DemoType;
  isBybitDemoLoading: boolean;

  diversifiedDemo: null | DiversifiedDemoType;
};

const initialState: initialStateType = {
  // User
  authStatus: AuthStatus.Unknown,
  userAuthAccounts: null,
  userProfile: null,
  isUserProfileLoading: false,
  accountStatuses: null,
  isAccountStatusesLoading: false,
  isOkxConnected: null,
  isBybitConnected: null,
  isBinanceFuturesConnected: null,
  isBinanceSpotConnected: null,
  isOkxRedirectFromApple: false,
  isBitgetConnected: null,
  isBitgetRedirectFromApple: false,

  // Message
  messages: null,
  notifications: null,
  isNotificationsLoading: false,

  // Nav
  navAccounts: [],
  isNavAccountsLoading: false,

  // Overview
  overviewSummary: null,
  isOverviewSummaryLoading: false,
  overviewSummaryStatus: null,
  strategies: [],
  isStrategiesLoading: false,
  subscribedAccounts: [],

  // Details
  strategyDetails: null,
  isStrategyDetailsLoading: false,

  // Integration New
  amountToStart: null,
  isLoadAmountToStartLoading: false,
  loadAmountToStartStatus: null,

  isSendAmountForStartLoading: false,
  sendAmountToStartStatus: null,

  changePositionModeStatus: null,
  isChangePositionModeLoading: false,

  isCheckPositionModeLoading: false,
  checkPositionModeStatus: null,
  checkAccountUnifiedStatus: null,

  addKeysStatus: null,
  isAddKeysLoading: false,

  // RenewKeys
  checkRenewKeysStatus: null,
  isCheckRenewKeysLoading: false,

  saveRenewKeysStatus: null,
  isSaveRenewKeysLoading: false,

  isFixDivergenceLoading: false,

  // Referrals
  referrals: null,
  isReferralsLoading: false,

  // Chart
  faqChart: null,
  calcChart: null,
  isCalcChartLoading: false,

  // Payment
  clientSecret: null,
  paymentsInfo: null,
  isPaymentsInfoLoading: false,

  paymentMethods: [],
  isPaymentMethodsLoading: false,
  methodsOrderStatus: null,
  isAddedCardLoading: false,
  addedCardStatus: null,

  lastPayments: [],
  isLastPaymentsLoading: false,

  cryptoWallet: null,
  depositInLastPaymentsStatus: null,

  failedTransactions: [],
  isFailedTransactionsAgainLoading: false,
  isFailedTransactionsLoading: false,

  isCryptoFormChosen: false,
  isCardFormChosen: false,

  // Interface
  isFirstScreenRendered: false,
  isPartnershipFormFocused: false,

  openedHint: null,
  readHintIds: [],

  isNavAccountsOpened: true,
  isNotificationsOpened: false,
  isPersonalOpened: false,
  isNavOpened: false,

  isRenewKeysFormOpened: false,
  isIntegrationOpened: false,

  coinToggles: {
    isBinanceSpot: false,
    isMonth: true,
  },

  // Modal
  isAuthModalOpened: false,
  isAuthModalOpenedFromExchanges: false,
  isErrorModalOpened: false,

  isStopModalOpened: false,
  stopModalId: null,

  isDeleteCardModalOpened: false,
  deleteCardModalId: null,
  isOkxModalOpened: false,
  isOkxModalRendered: false,
  isBitgetModalOpened: false,
  isBitgetModalRendered: false,
  isBybitModalOpened: false,
  isBybitModalRendered: false,

  // Calendar
  isIntegrationCalendarOpened: false,

  // Coins
  tradableCoins: [],
  isTradableCoinsLoading: false,
  coin: null,
  isCoinLoading: false,

  // Demo
  okxDemo: null,
  isOkxDemoLoading: false,

  binanceSpotDemo: null,
  isBinanceSpotDemoLoading: false,

  binanceFuturesDemo: null,
  isBinanceFuturesDemoLoading: false,

  bitgetFuturesDemo: null,
  isBitgetFuturesDemoLoading: false,

  bybitDemo: null,
  isBybitDemoLoading: false,

  diversifiedDemo: null,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    // User
    .addCase(requireAuthorization, (state, action) => {
      state.authStatus = action.payload;
    })
    .addCase(loadUserAuthAccountsStatus, (state, action) => {
      state.userAuthAccounts = action.payload;
    })
    .addCase(loadUserProfile, (state, action) => {
      state.userProfile = action.payload;
    })
    .addCase(setUserProfileLoading, (state, action) => {
      state.isUserProfileLoading = action.payload;
    })
    .addCase(setAccountStatuses, (state, action) => {
      state.accountStatuses = action.payload;
    })
    .addCase(setIsAccountStatusesLoading, (state, action) => {
      state.isAccountStatusesLoading = action.payload;
    })
    .addCase(setOkxConnected, (state, action) => {
      state.isOkxConnected = action.payload;
    })
    .addCase(setBybitConnected, (state, action) => {
      state.isBybitConnected = action.payload;
    })
    .addCase(setBinanceFuturesConnected, (state, action) => {
      state.isBinanceFuturesConnected = action.payload;
    })
    .addCase(setBinanceSpotConnected, (state, action) => {
      state.isBinanceSpotConnected = action.payload;
    })
    .addCase(setOkxRedirectFromApple, (state, action) => {
      state.isOkxRedirectFromApple = action.payload;
    })
    .addCase(setBitgetConnected, (state, action) => {
      state.isBitgetConnected = action.payload;
    })
    .addCase(setBitgetRedirectFromApple, (state, action) => {
      state.isBitgetRedirectFromApple = action.payload;
    })

    // Message
    .addCase(requireMessages, (state, action) => {
      state.messages = action.payload;
    })
    .addCase(loadNotifications, (state, action) => {
      state.notifications = action.payload;
    })
    .addCase(setNotificationsLoading, (state, action) => {
      state.isNotificationsLoading = action.payload;
    })
    .addCase(resetNotificationsInMessages, (state, action) => {
      if (state.messages) {
        state.messages = {
          ...state.messages,
          bell: action.payload,
          referrals: action.payload,
          payments: action.payload,
        };
      }
    })

    // Nav
    .addCase(loadNavAccounts, (state, action) => {
      state.navAccounts = action.payload;
    })
    .addCase(setAccountsLoading, (state, action) => {
      state.isNavAccountsLoading = action.payload;
    })

    // Overview
    .addCase(loadOverviewSummary, (state, action) => {
      state.overviewSummary = action.payload;
    })
    .addCase(setOverviewSummaryLoading, (state, action) => {
      state.isOverviewSummaryLoading = action.payload;
    })
    .addCase(setOverviewSummaryStatus, (state, action) => {
      state.overviewSummaryStatus = action.payload;
    })
    .addCase(loadStrategies, (state, action) => {
      state.strategies = action.payload;
    })
    .addCase(setStrategiesLoading, (state, action) => {
      state.isStrategiesLoading = action.payload;
    })
    .addCase(addSubscribedAccount, (state, action) => {
      const id = action.payload;
      state.subscribedAccounts.push(id);
    })

    // Details
    .addCase(loadStrategyDetails, (state, action) => {
      state.strategyDetails = action.payload;
    })
    .addCase(setStrategyDetailsLoading, (state, action) => {
      state.isStrategyDetailsLoading = action.payload;
    })
    .addCase(setFixDivergenceLoading, (state, action) => {
      state.isFixDivergenceLoading = action.payload;
    })

    // RENEW KEYS
    .addCase(setSendRenewKeysToCheckStatus, (state, action) => {
      state.checkRenewKeysStatus = action.payload;
    })
    .addCase(setSendRenewKeysToCheckLoading, (state, action) => {
      state.isCheckRenewKeysLoading = action.payload;
    })

    .addCase(setSendRenewKeysToUpdateStatus, (state, action) => {
      state.saveRenewKeysStatus = action.payload;
    })
    .addCase(setSendRenewKeysToUpdateLoading, (state, action) => {
      state.isSaveRenewKeysLoading = action.payload;
    })

    //
    // LOAD AMOUNT
    .addCase(loadAmountToStart, (state, action) => {
      state.amountToStart = action.payload;
    })
    .addCase(setLoadAmountToStartLoading, (state, action) => {
      state.isLoadAmountToStartLoading = action.payload;
    })
    .addCase(setLoadAmountToStartStatus, (state, action) => {
      state.loadAmountToStartStatus = action.payload;
    })
    // SEND AMOUNT
    .addCase(setSendAmountToStartStatus, (state, action) => {
      state.sendAmountToStartStatus = action.payload;
    })
    .addCase(setSendAmountToStartLoading, (state, action) => {
      state.isSendAmountForStartLoading = action.payload;
    })
    // MODE
    .addCase(setCheckPositionModeStatus, (state, action) => {
      state.checkPositionModeStatus = action.payload;
    })
    .addCase(setCheckAccountUnifiedStatus, (state, action) => {
      state.checkAccountUnifiedStatus = action.payload;
    })
    .addCase(setCheckPositionModeLoading, (state, action) => {
      state.isCheckPositionModeLoading = action.payload;
    })
    .addCase(setChangePositionModeStatus, (state, action) => {
      state.changePositionModeStatus = action.payload;
    })
    .addCase(setChangePositionModeLoading, (state, action) => {
      state.isChangePositionModeLoading = action.payload;
    })
    // ADD KEYS
    .addCase(setAddKeysLoading, (state, action) => {
      state.isAddKeysLoading = action.payload;
    })
    .addCase(setAddKeysStatus, (state, action) => {
      state.addKeysStatus = action.payload;
    })
    //

    // Referrals
    .addCase(loadReferrals, (state, action) => {
      state.referrals = action.payload;
    })
    .addCase(setReferralsLoading, (state, action) => {
      state.isReferralsLoading = action.payload;
    })

    // Chart
    .addCase(loadFaqChart, (state, action) => {
      state.faqChart = action.payload;
    })
    .addCase(loadCalcChart, (state, action) => {
      state.calcChart = action.payload;
    })
    .addCase(setCalcChartLoading, (state, action) => {
      state.isCalcChartLoading = action.payload;
    })

    // Payment
    .addCase(loadClientSecret, (state, action) => {
      state.clientSecret = action.payload;
    })
    // Payment info
    .addCase(loadPaymentsInfo, (state, action) => {
      state.paymentsInfo = action.payload;
    })
    .addCase(setLoadPaymentsInfoLoading, (state, action) => {
      state.isPaymentsInfoLoading = action.payload;
    })

    .addCase(loadPaymentMethods, (state, action) => {
      state.paymentMethods = action.payload;
    })
    .addCase(setPaymentMethodsLoading, (state, action) => {
      state.isPaymentMethodsLoading = action.payload;
    })
    .addCase(setSendMethodsOrderStatus, (state, action) => {
      state.methodsOrderStatus = action.payload;
    })
    .addCase(addCard, (state, action) => {
      const card = action.payload;
      state.paymentMethods.push(card);
    })
    .addCase(setAddCardLoading, (state, action) => {
      state.isAddedCardLoading = action.payload;
    })
    .addCase(setSendAddedCardStatus, (state, action) => {
      state.addedCardStatus = action.payload;
    })
    .addCase(deleteCard, (state, action) => {
      const code = action.payload;
      const index = state.paymentMethods.findIndex(
        (item) => item.data.code === code
      );

      state.paymentMethods = [
        ...state.paymentMethods.slice(0, index),
        ...state.paymentMethods.slice(index + 1),
      ];
    })

    .addCase(loadLastPayments, (state, action) => {
      state.lastPayments = action.payload;
    })
    .addCase(setLastPaymentsLoading, (state, action) => {
      state.isLastPaymentsLoading = action.payload;
    })

    .addCase(loadCryptoWallet, (state, action) => {
      state.cryptoWallet = action.payload;
    })
    .addCase(addDepositInLastPayments, (state, action) => {
      state.lastPayments = [action.payload, ...state.lastPayments];
    })
    .addCase(setSendDepositInLastPaymentsStatus, (state, action) => {
      state.depositInLastPaymentsStatus = action.payload;
    })

    .addCase(loadFailedTransactions, (state, action) => {
      state.failedTransactions = action.payload;
    })
    .addCase(setFailedTransactionsLoading, (state, action) => {
      state.isFailedTransactionsLoading = action.payload;
    })
    .addCase(setFailedTransactionsAgainLoading, (state, action) => {
      state.isFailedTransactionsAgainLoading = action.payload;
    })

    .addCase(setCryptoFormChosen, (state, action) => {
      state.isCryptoFormChosen = action.payload;
    })
    .addCase(setCardFormChosen, (state, action) => {
      state.isCardFormChosen = action.payload;
    })

    // Interface
    .addCase(setFirstScreenRendered, (state, action) => {
      state.isFirstScreenRendered = action.payload;
    })
    .addCase(setPartnershipFormFocused, (state, action) => {
      state.isPartnershipFormFocused = action.payload;
    })

    .addCase(setOpenedHint, (state, action) => {
      state.openedHint = action.payload;
    })
    .addCase(addReadHintId, (state, action) => {
      const id = action.payload;
      state.readHintIds.push(id);
    })

    .addCase(setNavAccountsOpened, (state, action) => {
      state.isNavAccountsOpened = action.payload;
    })
    .addCase(setPersonalOpened, (state, action) => {
      state.isPersonalOpened = action.payload;
    })
    .addCase(setNavOpened, (state, action) => {
      state.isNavOpened = action.payload;
    })
    .addCase(setNotificationsOpened, (state, action) => {
      state.isNotificationsOpened = action.payload;
    })
    .addCase(setIntegrationOpened, (state, action) => {
      state.isIntegrationOpened = action.payload;
    })
    .addCase(setRenewKeysOpened, (state, action) => {
      state.isRenewKeysFormOpened = action.payload;
    })
    .addCase(setCoinToggles, (state, action) => {
      state.coinToggles = action.payload;
    })

    // Modal
    .addCase(setAuthModalOpened, (state, action) => {
      state.isAuthModalOpened = action.payload;
    })
    .addCase(setAuthModalOpenedFromExchanges, (state, action) => {
      state.isAuthModalOpenedFromExchanges = action.payload;
    })
    .addCase(setErrorModalOpened, (state, action) => {
      state.isErrorModalOpened = action.payload;
    })
    .addCase(resetErrorInMessages, (state, action) => {
      if (state.messages) {
        state.messages = {
          ...state.messages,
          error: action.payload,
        };
      }
    })
    .addCase(setStopModalOpened, (state, action) => {
      state.isStopModalOpened = action.payload;
    })
    .addCase(setStopModalId, (state, action) => {
      state.stopModalId = action.payload;
    })
    .addCase(setDeleteCardModalOpened, (state, action) => {
      state.isDeleteCardModalOpened = action.payload;
    })
    .addCase(setDeleteCardModalId, (state, action) => {
      state.deleteCardModalId = action.payload;
    })
    .addCase(setOkxModalOpened, (state, action) => {
      state.isOkxModalOpened = action.payload;
    })
    .addCase(setOkxModalRendered, (state, action) => {
      state.isOkxModalRendered = action.payload;
    })
    .addCase(setBitgetModalOpened, (state, action) => {
      state.isBitgetModalOpened = action.payload;
    })
    .addCase(setBitgetModalRendered, (state, action) => {
      state.isBitgetModalRendered = action.payload;
    })
    .addCase(setBybiytModalOpened, (state, action) => {
      state.isBybitModalOpened = action.payload;
    })
    .addCase(setBybitModalRendered, (state, action) => {
      state.isBybitModalRendered = action.payload;
    })

    .addCase(setIntegrationCalendarOpened, (state, action) => {
      state.isIntegrationCalendarOpened = action.payload;
    })

    // Coins
    .addCase(loadTradableCoins, (state, action) => {
      state.tradableCoins = action.payload;
    })
    .addCase(setTradableCoinsLoading, (state, action) => {
      state.isTradableCoinsLoading = action.payload;
    })
    .addCase(loadCoin, (state, action) => {
      state.coin = action.payload;
    })
    .addCase(setCoinLoading, (state, action) => {
      state.isCoinLoading = action.payload;
    })

    // DEMO
    .addCase(loadOkxDemo, (state, action) => {
      state.okxDemo = action.payload;
    })
    .addCase(setOkxDemoLoading, (state, action) => {
      state.isOkxDemoLoading = action.payload;
    })

    .addCase(loadBinanceSpotDemo, (state, action) => {
      state.binanceSpotDemo = action.payload;
    })
    .addCase(setBinanceSpotDemoLoading, (state, action) => {
      state.isBinanceSpotDemoLoading = action.payload;
    })

    .addCase(loadBinanceFuturesDemo, (state, action) => {
      state.binanceFuturesDemo = action.payload;
    })
    .addCase(setBinanceFuturesDemoLoading, (state, action) => {
      state.isBinanceFuturesDemoLoading = action.payload;
    })

    .addCase(loadBitgetFuturesDemo, (state, action) => {
      state.bitgetFuturesDemo = action.payload;
    })
    .addCase(setBitgetFuturesDemoLoading, (state, action) => {
      state.isBitgetFuturesDemoLoading = action.payload;
    })

    .addCase(loadBybitDemo, (state, action) => {
      state.bybitDemo = action.payload;
    })
    .addCase(setBybitDemoLoading, (state, action) => {
      state.isBybitDemoLoading = action.payload;
    })

    .addCase(loadDiversifiedDemo, (state, action) => {
      state.diversifiedDemo = action.payload;
    });
});

export { reducer };
